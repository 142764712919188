import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Gewichtung4() {
  const question = {
    title: 'Gewichtung - 4. Teil',
    headline: 'Den 4.Teil hast du schon fast geschafft!',
    subline: (
      <>
        Bitte wähle hier noch <strong>zwei</strong> Dinge aus, die dir für den
        4.Teil besonders wichtig sind:
      </>
    ),
  }

  const answers = [
    {
      name: 'Wichtiger',
      id: 'Altersstruktur',
      value: 'Altersstruktur',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Teamgroesse',
      value: 'Teamgröße',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Supervision',
      value: 'Supervision',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Teambildende-Maßnahmen',
      value: 'Teambildende Maßnahmen',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'festesTeamSpringerpool',
      value: 'festes Team vs. Springerpool',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Kommunikation',
      value: 'Kommunikation',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-red-circles">
        <Navigation />

        <section className="quiz pt-0">
          <div className="row text-center">
            <img
              className="mx-auto"
              data-src="/img/handlettering/handlettering-tschakka.svg"
              src="/img/handlettering/handlettering-tschakka.svg"
              alt="Tschakka!"
              width="400"
            />
            <MatchingQuestion
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="multi"
              answers={answers}
              color="green"
              limited
              index={'g4'}
            />
          </div>
        </section>

        <MatchingNavigation
          color="white"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/gratulation`}
          prev={`${AREA_CARE_PATH}/spielen/frage-35`}
          index={'g4'}
        />

        <Icon position="1" name="flower" />
        <Icon position="2" name="brainstorming" />
        <Icon position="3" name="megaphone" />
        <Icon position="4" name="network" />
      </main>
    </>
  )
}
